import { createI18n } from 'vue-i18n';
import { store } from '@/store';
import { isLocaleSupported, getBrowserLocale } from '../utils/locales';
import { ENGLISH } from '../locales';
import en from '../locales/en.json';
import es from '../locales/es.json';
import fr from '../locales/fr.json';
import ja from '../locales/ja.json';
import ko from '../locales/ko.json';
import ru from '../locales/ru.json';
import zh from '../locales/zh.json';

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  const storedLocale = localStorage.getItem('locale');
  const advSetLanguage = store.getters.getConfiguration.i18nLanguage
    ? store.getters.getConfiguration.i18nLanguage[0]
    : '';

  if (storedLocale) {
    return storedLocale;
  }

  if (isLocaleSupported(browserLocale)) {
    return browserLocale;
  }

  if (advSetLanguage && advSetLanguage.length) {
    return advSetLanguage;
  }
  return process.env.VUE_APP_I18N_LOCALE || ENGLISH.code;
}

export default function geti18nFromConfig() {
  return createI18n({
    legacy: false,
    locale: getStartingLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || ENGLISH.code,
    messages: {
      en,
      es,
      fr,
      ja,
      ko,
      ru,
      zh,
    },
  });
}
