<template>
  <div class="wc-radio-wrapper custom-control custom-radio">
    <input
      :id="id"
      type="radio"
      :name="name"
      class="wc-radio-wrapper_input custom-control-input"
      :checked="checked"
      :value="modelValue"
      :disabled="disabled"
      @change="$emit('update:modelValue', id)"
    />
    <WCLabel class="wc-radio-wrapper_content w-100 custom-control-label" :for="id">
      <slot></slot>
    </WCLabel>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import WCLabel from '../WCLabel/WCLabel.vue';

export default {
  components: { WCLabel },
  name: 'WCRadioButton',
  emits: ['update:modelValue'],
  props: {
    id: {
      type: String,
      default: () => uuid(),
    },
    name: {
      type: String,
    },
    modelValue: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked() {
      return this.id === this.modelValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-radio-wrapper {
  display: flex;
}

.wc-radio-wrapper_input {
  margin: 0 $margin-2 0 $margin-2;
}

.wc-radio-wrapper_content > * {
  position: relative;
  margin-bottom: 0;
  vertical-align: middle;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--primary, $primary);
  border-color: var(--primary, $primary);
}
.custom-control-label::before {
  border-color: var(--dark, $dark);
}
</style>
